.input_drop_down_p {
    position: relative;
}
.ant-select.input_drop_down {
    position: absolute;
    bottom: 0;
    width: 100%;
    .ant-select-selector {
        width: 0 !important;
        height: 0 !important;
        overflow: hidden;
        border: 0 !important;
    }
    .ant-select-arrow {
        width: 0 !important;
        height: 0 !important;
        overflow: hidden;
    }
}
